const defaultState = {
    loadingValue : false,
    billValue:'null',
    menuValue: ['3'],
}

const reducer = (state = defaultState,action)=>{
    switch (action.type) {
        //加载等待圈圈
        case 'changeLoading':
            let changeLoading = JSON.parse(JSON.stringify(state)) //深度拷贝state
            changeLoading.loadingValue = action.value
            return changeLoading    
        //更换所选订单数据
        case 'changeBill':
            let changeBill = JSON.parse(JSON.stringify(state)) //深度拷贝state
            changeBill.billValue = action.value
            return changeBill    
        //更换menu number
        case 'changeMenu':
            let changeMenu = JSON.parse(JSON.stringify(state)) //深度拷贝state
            changeMenu.menuValue = action.value
            return changeMenu    
        default:
            return state
    }
}

export default reducer;