//加载等待圈圈
export const changeLoading=(value)=>({
    type:'changeLoading',
    value
})
//更换所选订单数据
export const changeBill=(value)=>({
    type:'changeBill',
    value
})
//更换menu number
export const changeMenu=(value)=>({
    type:'changeMenu',
    value
})