import React from 'react';
import Pusher from 'pusher-js';
import axios from 'axios'
import '../../assets/Chat.css'

class Chat extends React.Component {
  constructor(props) {
		super(props);
    this.state={
    }
  }
  // 接受PUSHER广播
  getText = () => {
    Pusher.logToConsole = true;

    var pusher = new Pusher('3cc416c5ea6760751142', {
      cluster: 'ap3'
    });
    
    var channel = pusher.subscribe('my-channel');
    channel.bind('my-event', function(data) {
      let box = document.getElementsByClassName('chat-box')[0];
      if(data.info[0].laravel_name==null){
        box.innerHTML += '匿名:' + data.info[0].laravel_content + "<br/>";
      }else{
        box.innerHTML += data.info[0].laravel_name + ':' + data.info[0].laravel_content + "<br/>";
      }

    });
  }
  componentDidMount(){
    this.getText()
  }
  //清空聊天
  deleteText = () =>{
    let box = document.getElementsByClassName('chat-box')[0];
    box.innerHTML = '';
  }
  //发送消息
  sendText = () =>{
    //判断输入框是否有内容
    if(document.getElementsByClassName('chat-input')[0].value!==''){
      //获取内容
      let arr ={
        'name' : this.refs.name.value,
        'content' : this.refs.content.value,
      }
      let idData = arr;
        //let idData = "contentAll="+JSON.stringify(arr);react和laravel不需要转码
      axios.post('/api/send',idData)
        .then((res)=>{
          let mes = document.getElementsByClassName('chat-input')[0];
          mes.value = '';
        })
        .catch((error)=>{console.log('axios 获取数据失败'+error)})  
    }
  }

  render(){
    return (
      <div className='chat'>
        <div className="chat-top">Pusher</div>
        <input placeholder='请输入你是谁...' className='chat-name' ref='name'></input>
        <p className="chat-box"></p>
        <input placeholder='请输入消息...' className='chat-input' ref='content'></input>
        <button className="chat-button" onClick={this.sendText}>发送</button>
        <button className="chat-delete" onClick={this.deleteText}>清空信息</button>
      </div>
    );
  }
}
  
  export default Chat;
  