import axios from 'axios'
/*跨域请求加上：withCredentials: true
export const axiosSign = (serveURL, data) => {
  return axios.post(serveURL, data)
}
*/
export const axiosSign = (serveURL, data) => {
  return axios.post('https://invoice-api.kamome-travel.info/' + serveURL, data, {
    withCredentials: true
  })
}
