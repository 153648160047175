import React from 'react';
import './assets/App.css'
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Home from './views/home/Home';
import PageIndex from './views/page/PageIndex';
import Chat from './views/home/Chat';
import Login from './views/login/Login';
import store from './reducer'
import { Spin } from 'antd';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
    store.subscribe(this.storeChange) //订阅Redux的状态
  }
  storeChange = () => {
    this.setState(store.getState())
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return
    }
  }//在组件销毁的时候将异步方法撤销
  render() {
    return (
      <Spin size="large" spinning={store.getState().loadingValue} delay={500}>
        <div className="App">
          <Router>
            <Switch>
              <Route path="/home" component={Home} />
              <Route path="/pageIndex" component={PageIndex} />
              <Route path="/chat" component={Chat} />
              <Route path="/login" component={Login} />
              <Redirect from="/*" to="/login" />
            </Switch>
          </Router>
        </div>
      </Spin>
    );
  }
}

export default App;
