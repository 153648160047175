import React from 'react';
import '../../assets/ContractContent.css'
import { message, Button, Modal, Input } from 'antd';
import store from '../../reducer'
import { changeLoading } from '../../reducer/actionCreators'
import { axiosSign } from '../../api/axiosSign'

const { TextArea } = Input;

class ContractContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      journey_data: [1, 2, 3],
      phone_data: [4, 5, 6],
      loading: false,
      data: [],

      recordSend: '',//记录号
      operatorSend: '',//担当
      sold_timeSend: '',//开票时间
      airlineSend: '',//航空公司
      ticketSend: '',//机票号
      nameSend: '',//姓名
      priceSend: '',//票价
      taxSend: '',//税
      rebateSend: '',//返点
      companySend: '',//开票公司
      manage_numSend: '',//管理番号

      phoneSend: '',//电话
      journeySend: '',//行程
      remarkSend: '',//备注
    }
    store.subscribe(this.storeChange) //订阅Redux的状态
  }
  storeChange = () => {
    this.setState(store.getState())
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return
    }
  }//在组件销毁的时候将异步方法撤销

  //页面注入数据

  dataInput = () => {
    //获取URL店铺号码
    let contractId = {
      'contractKey': parseInt(this.props.location.search.substring(1)),
    }
    axiosSign('getTicketById', contractId)
      .then((res) => {
        console.log(res.data)

        let v = res.data.journey.split(',');

        let w = res.data.phone.split(',');

        //列表数据赋值
        this.setState({
          data: res.data,
          journey_data: v,//行程分行
          phone_data:w,//电话分行

          recordSend: res.data.record,//记录号
          operatorSend: res.data.operator,//担当
          sold_timeSend: res.data.sold_time,//开票时间
          airlineSend: res.data.airline,//航空公司
          ticketSend: res.data.ticket,//机票号
          nameSend: res.data.name,//姓名
          priceSend: res.data.price,//票价
          taxSend: res.data.tax,//税
          rebateSend: res.data.rebate,//返点
          companySend: res.data.company,//开票公司
          manage_numSend: res.data.manage_num,//管理番号

          phoneSend: res.data.phone,//电话
          journeySend: res.data.journey,//行程
          remarkSend: res.data.remark,//备注
        })

      })
      .catch((error) => { console.log('axios 获取数据失败' + error) })
  }

  //初始化页面
  UNSAFE_componentWillMount() {
    this.dataInput()
  }

  //返回
  goBack = () => {
    store.dispatch(changeLoading(true))
    setTimeout(() => {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
      this.props.history.goBack();
      store.dispatch(changeLoading(false))
    }, 1000)
  }

  //对话框点击OK
  handleOk = () => {
    let data = {
      id: this.state.data.id,//ID
      record: this.state.recordSend,//记录号
      operator: this.state.operatorSend,//担当
      sold_time: this.state.sold_timeSend,//开票时间
      airline: this.state.airlineSend,//航空公司
      ticket: this.state.ticketSend,//机票号
      name: this.state.nameSend,//姓名
      price: this.state.priceSend,//票价
      tax: this.state.taxSend,//税
      rebate: this.state.rebateSend,//返点
      company: this.state.companySend,//开票公司
      manage_num: this.state.manage_numSend,//管理番号

      phone: this.state.phoneSend,//电话
      journey: this.state.journeySend,//行程
      remark: this.state.remarkSend,//备注
    }
    console.log(data)
    axiosSign('updateTicket', data)
      .then((res) => {
        message.success(res.data)
        this.setState({
          isModalOpen: false
        })
        this.dataInput()
      })
      .catch((error) => { 
        message.warning('字符过长或有误')
        console.log('axios 获取数据失败' + error) 
      })
  }

  //对话框点击取消
  handleCancel = () => {
    this.setState({
      isModalOpen: false
    })
  }

  //打开对话框
  showModal = () => {
    this.setState({
      isModalOpen: true
    })
  }

  //绑定input通用方法
  changeData = (data, e) => {
    console.log(data + '=' + e.target.value)
    this.setState({
      [data]: e.target.value
    })
  }

  render() {
    return (
      <div className='contractcontent'>
        <div className='contractcontent-title flex-between'>

          <div className='flex-between'>
            <div style={{ marginRight: '5px' }}>出票信息</div>
            <Button onClick={this.showModal}>编辑</Button>
          </div>
          <p onClick={this.goBack} className='contractcontent-back'>戻る</p>
        </div>
        <div className='contractcontent-content'>
          <div className='contractcontent-content-search'>
            <div className='contractcontent-content-search-list'>
              登录时间 : {this.state.data.time}
            </div>
            <div className='contractcontent-content-search-list'>
              记录号 : {this.state.data.record}
            </div>
            <div className='contractcontent-content-search-list'>
              担当 : {this.state.data.operator}
            </div>
            <div className='clear-both'></div>
          </div>

          <div className='contractcontent-content-search'>
            <div className='contractcontent-content-search-title'>
              机票信息
            </div>
            <div className='contractcontent-content-search-list'>
              开票时间 : {this.state.data.sold_time}
            </div>
            <div className='contractcontent-content-search-list'>
              航空公司 : {this.state.data.airline}
            </div>
            <div className='contractcontent-content-search-list'>
              机票号 : {this.state.data.ticket}
            </div>
            <div className='contractcontent-content-search-list'>
              姓名 : {this.state.data.name}
            </div>
            <div className='contractcontent-content-search-list'>
              票价 : {this.state.data.price}
            </div>
            <div className='contractcontent-content-search-list'>
              税金 : {this.state.data.tax}
            </div>
            <div className='contractcontent-content-search-list'>
              返点 : {this.state.data.rebate}
            </div>
            <div className='contractcontent-content-search-list'>
              开票公司 : {this.state.data.company}
            </div>
            <div className='contractcontent-content-search-list'>
              数据类型 : {this.state.data.type}
            </div>
            <div className='contractcontent-content-search-list'>
              管理番号 : {this.state.data.manage_num}
            </div>
            <div className='clear-both'></div>
          </div>

          <div className='contractcontent-content-search'>
            <div className='contractcontent-content-search-list' style={{ width: '100%' }}>
              电话 : <br /><br />
              {this.state.phone_data.map((item, index) =>
                <p key={index}>{item}</p>
              )}
            </div>
            <div className='clear-both'></div>
          </div>

          <div className='contractcontent-content-search'>
            <div className='contractcontent-content-search-list' style={{ width: '100%' }}>
              行程 :<br /><br />
              {this.state.journey_data.map((item, index) =>
                <p key={index}>{item}</p>
              )}
            </div>
            <div className='clear-both'></div>
          </div>

          <div className='contractcontent-content-search'>
            <div className='contractcontent-content-search-list' style={{ width: '100%' }}>
              备注 :{this.state.data.remark}
            </div>
            <div className='clear-both'></div>
          </div>

          <div style={{ height: '24px' }}></div>

        </div>

        <Modal
          width={960}
          title="信息編集"
          open={this.state.isModalOpen}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <div className='contractcontent-content-search'>
            <div className='contractcontent-content-search-list'>
              记录号 :
              <Input value={this.state.recordSend} onChange={(e) => this.changeData('recordSend', e)} />
            </div>
            <div className='contractcontent-content-search-list'>
              担当 :
              <Input value={this.state.operatorSend} onChange={(e) => this.changeData('operatorSend', e)} />
            </div>
            <div className='contractcontent-content-search-list'>
              开票时间 :
              <Input value={this.state.sold_timeSend} onChange={(e) => this.changeData('sold_timeSend', e)} />
            </div>
            <div className='contractcontent-content-search-list'>
              航空公司 :
              <Input value={this.state.airlineSend} onChange={(e) => this.changeData('airlineSend', e)} />
            </div>
            <div className='contractcontent-content-search-list'>
              机票号 :
              <Input value={this.state.ticketSend} onChange={(e) => this.changeData('ticketSend', e)} />
            </div>
            <div className='contractcontent-content-search-list'>
              姓名 :
              <Input value={this.state.nameSend} onChange={(e) => this.changeData('nameSend', e)} />
            </div>
            <div className='contractcontent-content-search-list'>
              票价 :
              <Input value={this.state.priceSend} onChange={(e) => this.changeData('priceSend', e)} />
            </div>
            <div className='contractcontent-content-search-list'>
              税金 :
              <Input value={this.state.taxSend} onChange={(e) => this.changeData('taxSend', e)} />
            </div>
            <div className='contractcontent-content-search-list'>
              返点 :
              <Input value={this.state.rebateSend} onChange={(e) => this.changeData('rebateSend', e)} />
            </div>
            <div className='contractcontent-content-search-list'>
              开票公司 :
              <Input value={this.state.companySend} onChange={(e) => this.changeData('companySend', e)} />
            </div>
            <div className='contractcontent-content-search-list'>
              管理番号 :
              <Input value={this.state.manage_numSend} onChange={(e) => this.changeData('manage_numSend', e)} />
            </div>
            <div className='clear-both'></div>
          </div>
          <div className='contractcontent-content-search'>
            <div className='contractcontent-content-search-list' style={{ width: '100%' }}>
              电话 :
              <TextArea value={this.state.phoneSend} onChange={(e) => this.changeData('phoneSend', e)} />
            </div>
            <div className='contractcontent-content-search-list' style={{ width: '100%' }}>
              行程 :
              <TextArea style={{ 'height': '150px' }} value={this.state.journeySend} onChange={(e) => this.changeData('journeySend', e)} />
            </div>
            <div className='contractcontent-content-search-list' style={{ width: '100%' }}>
              备注 :
              <TextArea value={this.state.remarkSend} onChange={(e) => this.changeData('remarkSend', e)} />
            </div>
            <div className='clear-both'></div>
          </div>

        </Modal>

      </div>
    );
  }
}

export default ContractContent;
