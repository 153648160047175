import React from 'react';

class pageIndex extends React.Component {
    constructor(props) {
      super(props);
      this.state={
      }
    }
    render(){
      return (
        <div>
            pageIndex
        </div>
      );
    }
  }
  
  export default pageIndex;
  