import React from 'react';
import '../../assets/Login.css'
import { Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import store from '../../reducer'
import { changeLoading } from '../../reducer/actionCreators'
import { axiosSign } from '../../api/axiosSign'

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputId: '',//登录-用户名
      inputPw: '',//登录-用户密码
    }
    store.subscribe(this.storeChange) //订阅Redux的状态
  }
  storeChange = () => {
    this.setState(store.getState())
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return
    }
  }//在组件销毁的时候将异步方法撤销

  //改变ID输入框内容
  changeInputId = (e) => {
    //console.log('ID:' + e.target.value)
    this.setState({
      inputId: e.target.value
    })
  }

  //改变PW输入框内容
  changeInputPw = (e) => {
    //console.log('PW:' + e.target.value)
    this.setState({
      inputPw: e.target.value
    })
  }

  //登录 
  goHome = () => {
    let data = new FormData();
    data.append('username', this.state.inputId);
    data.append('password', this.state.inputPw);

    axiosSign('login', data)
      .then((res) => {
        let action = changeLoading(true)
        store.dispatch(action)
        setTimeout(() => {
          store.dispatch(changeLoading(false))
          if (res.data.Message === '登录成功！！！！！') {
            message.success('Welcome');
            document.documentElement.scrollTop = document.body.scrollTop = 0;
            this.props.history.replace('/home');
          } else {
            message.error("验证失败");
          }
          console.log(res.data)
        }, 1000)
      })
      .catch((error) => { console.log('axios 获取数据失败' + error) })
  }

  render() {
    return (
      <div className='login'>
        <div className='login-box'>
          <div className='login-box-title'>
            請求書発行システム<br />
          </div>
          <Input className='login-box-id' value={this.state.inputId} onChange={(e) => { this.changeInputId(e) }} size="large" placeholder="アカウント" prefix={<UserOutlined style={{ color: '#1890ff' }} />} />
          <Input.Password size="large" value={this.state.inputPw} onChange={(e) => { this.changeInputPw(e) }} className='login-box-pw' placeholder="パスワード" prefix={<LockOutlined style={{ color: '#1890ff' }} />} />
          <Button style={{ width: '100%' }} size="large" type="primary" onClick={this.goHome}>登录</Button>
        </div>
      </div>
    );
  }
}

export default Login;
