import React from 'react';
import '../../assets/ContractList.css'
import { Input, Button, Space, Table, message, Modal, DatePicker } from 'antd';
import store from '../../reducer'
import { changeLoading, changeBill, changeMenu } from '../../reducer/actionCreators'
import { axiosSign } from '../../api/axiosSign'
import axios from 'axios'

const { RangePicker } = DatePicker;
const dateFormatList = ['YYYY/MM/DD', 'YYYY/MM/DD'];

class ContractList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      manage_id: 0,//当前选中ID
      isDELOpen: false,//DEL弹窗开关
      isAllDELOpen: false,//批量DEL弹窗开关

      selectedRowKeys: [], // Check here to configure the default column
      loading: false,

      company:'',//查询列表-会社名
      number:'',//查询列表-請求番号
      startDate:['', ''],//查询列表-請求日
      endDate:['', ''],//查询列表-お支払期限
      price:'',//查询列表-合計金額
      
      startDateValue: ['', ''],//查询列表（請求日）(UI显示用)
      endDateValue: ['', ''],//查询列表（お支払期限）(UI显示用)

      data: [],
      mockData: [
        {
          Name: "Allen",
          Gender: "Male",
          Height: "175"
        },
        {
          Name: "Tom",
          Gender: "Male",
          Height: "180"
        },
        {
          Name: "Jane",
          Gender: "Female",
          Height: "170"
        }
      ],
      columns: [
        {
          title: '請求番号',
          dataIndex: 'id',
        },
        {
          title: '会社名',
          dataIndex: 'company',
        },
        {
          title: '請求日',
          dataIndex: 'startDate',
        },
        {
          title: 'お支払期限',
          dataIndex: 'endDate',
        },
        {
          title: '発行会社',
          dataIndex: 'billType',
        },
        {
          title: '合計',
          dataIndex: 'price',
        },
        {
          title: '発行者',
          dataIndex: 'user',
        },
        {
          title: '発行日',
          dataIndex: 'createDate',
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'operation',
          render: () => (
            <Space size="middle">
              <p onClick={this.downloadBill} style={{ color: '#1890ff', cursor: 'pointer', margin: '0' }}>ダウンロード</p>
              <p onClick={this.copy_bill} style={{ color: '#1890ff', cursor: 'pointer', margin: '0' }}>コピー</p>
              <p onClick={this.changeDEL} style={{ color: 'red', cursor: 'pointer', margin: '0' }}>削除</p>
            </Space>
          ),
        },
      ]
    }
    store.subscribe(this.storeChange) //订阅Redux的状态
  }
  storeChange = () => {
    this.setState(store.getState())
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return
    }
  }//在组件销毁的时候将异步方法撤销

  //初始化
  UNSAFE_componentWillMount() {
    //更新menu
    store.dispatch(changeMenu(['1']))

    this.contract_list()
  }

  //表格注入临时数据(最近1000张请求书)
  contract_list = () => {
    axiosSign('find1000Bill')
      .then((res) => {
        console.log(res.status)
        if(res.status === 400){
          this.props.history.push('/login');
          message.success('登録してください')
        }else{
          this.setState({
            data: res.data
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  //再次下载请求书
  downloadBill = (e) => {
    e.persist()
    store.dispatch(changeLoading(true))
    setTimeout(() => {
      let contractId = e.target.parentNode.parentNode.parentNode.parentNode.childNodes[1].innerHTML
      console.log(contractId)
      let data = {
        'contractKey': Number(contractId),
      }
      axios.post('https://invoice-api.kamome-travel.info/downloadBill', data, {
        // 设置responseType对象格式为blob
        responseType: "blob",
      }).then((res) => {
          // 创建下载的链接
          const url = window.URL.createObjectURL(new Blob([res.data],
          // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式                          
          {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
          const link = document.createElement('a');
          link.href = url;
          // 从header中获取服务端命名的文件名
          const fileName = decodeURI(res.headers['請求書']);
          link.setAttribute('請求書', fileName);
          document.body.appendChild(link);
          link.click();
          message.success('下载成功');
        })
        .catch((error) => {
          console.log('axios 获取数据失败' + error)
          message.warning('エラー');
        })

      store.dispatch(changeLoading(false))
    }, 1000)
  }

  //copy请求书
  copy_bill = (e) => {
    e.persist()
    var contractId = e.target.parentNode.parentNode.parentNode.parentNode.childNodes[1].innerHTML
    console.log(contractId)

    this.state.data.map((val,index)=>{
      if(val.id == contractId){
        //将选择的请求书数据保存到redux
        store.dispatch(changeBill(val))
        //跳转去发行请求书
        this.props.history.push('/home/systemoption');
      }
    })
  }

  //清空选中
  start = () => {
    this.setState({
      loading: true
    });
    // ajax request after empty completing
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };

  //选取列表
  onSelectChange = (selectedRowKeys) => {
    console.log('选中了: ', selectedRowKeys);
    this.setState({
      selectedRowKeys
    });
  };

  //重置输入框内容
  resetOrderList = () => {
    this.contract_list()
    this.setState({
      company: '',//查询列表-会社名
      startDateValue: ['', ''],//查询列表（出票日期）
      endDateValue: ['', ''],//查询列表（出票日期）(重置用)
      number: '',//查询列表-請求番号
      price:'',//查询列表-合計金額
    })
  }

  //查询列表（会社名）
  getListByCompany = () => {
    let data = {
      'company': this.state.company,
    }
    if (this.state.company !== '') {
      store.dispatch(changeLoading(true))
      setTimeout(() => {
        axiosSign('getListByCompany', data)
          .then((res) => {
            console.log(res.data)
            this.setState({
              data: res.data
            })
          })
          .catch((error) => { console.log('axios 获取数据失败' + error) })

        store.dispatch(changeLoading(false))
      }, 1000)
    } else {
      message.warning('未输入内容');
    }
  }

  //查询列表（請求番号）
  getListByNumber = () => {
    let data = {
      'number': this.state.number,
    }
    if (this.state.number !== '') {
      store.dispatch(changeLoading(true))
      setTimeout(() => {
        axiosSign('getListByNumber', data)
          .then((res) => {
            console.log(res.data)
            this.setState({
              data: res.data
            })
          })
          .catch((error) => { console.log('axios 获取数据失败' + error) })

        store.dispatch(changeLoading(false))
      }, 1000)
    } else {
      message.warning('未输入内容');
    }
  }

  //查询列表（請求日）
  getListByStartDate = () => {
    let data = {
      'startDate1': this.state.startDate[0],
      'startDate2': this.state.startDate[1]
    }
    if (this.state.startDate[0] !== '') {
      store.dispatch(changeLoading(true))
      setTimeout(() => {
        axiosSign('getListByStartDate', data)
          .then((res) => {
            console.log(res.data)
            this.setState({
              data: res.data
            })
          })
          .catch((error) => { console.log('axios 获取数据失败' + error) })

        store.dispatch(changeLoading(false))
      }, 1000)
    } else {
      message.warning('未输入内容');
    }
  }

  //查询列表（お支払期限）
  getListByEndDate = () => {
    let data = {
      'endDate1': this.state.endDate[0],
      'endDate2': this.state.endDate[1]
    }
    if (this.state.endDate[0] !== '') {
      store.dispatch(changeLoading(true))
      setTimeout(() => {
        axiosSign('getListByEndDate', data)
          .then((res) => {
            console.log(res.data)
            this.setState({
              data: res.data
            })
          })
          .catch((error) => { console.log('axios 获取数据失败' + error) })

        store.dispatch(changeLoading(false))
      }, 1000)
    } else {
      message.warning('未输入内容');
    }
  }

  //查询列表（請求番号）
  getListByPrice = () => {
    let data = {
      'price': this.state.price,
    }
    if (this.state.price !== '') {
      store.dispatch(changeLoading(true))
      setTimeout(() => {
        axiosSign('getListByPrice', data)
          .then((res) => {
            console.log(res.data)
            this.setState({
              data: res.data
            })
          })
          .catch((error) => { console.log('axios 获取数据失败' + error) })

        store.dispatch(changeLoading(false))
      }, 1000)
    } else {
      message.warning('未输入内容');
    }
  }

  //删除契约
  softDeleteContract = () => {
    store.dispatch(changeLoading(true))
    setTimeout(() => {
      let contractId = this.state.manage_id
      console.log(contractId)
      let data = {
        'contractKey': Number(contractId),
      }
      axiosSign('softDelBillById', data)
        .then((res) => {
          this.setState({
            isDELOpen: false
          })
          console.log(res.data)
          this.contract_list()
          message.success('削除成功');
        })
        .catch((error) => {
          console.log('axios 获取数据失败' + error)
          message.warning('エラー');
        })

      store.dispatch(changeLoading(false))
    }, 1000)
  }

  //批量删除契约
  softAllDeleteContract = () => {
    store.dispatch(changeLoading(true))
    setTimeout(() => {
      let data = {
        'contractKeys': this.state.selectedRowKeys,
      }
      axiosSign('softAllDelBillById', data)
        .then((res) => {
          this.setState({
            isAllDELOpen: false,
            selectedRowKeys: []
          })
          console.log(res.data)
          this.contract_list()
          message.success('削除成功');
        })
        .catch((error) => {
          console.log('axios 获取数据失败' + error)
          message.warning('エラー');
        })

      store.dispatch(changeLoading(false))
    }, 1000)
  }

  //打开DEL弹窗
  changeDEL = (e) => {
    e.persist()
    let contractId = e.target.parentNode.parentNode.parentNode.parentNode.childNodes[1].innerHTML
    console.log(contractId)
    this.setState({
      isDELOpen: true,
      manage_id: contractId
    })
  };

  //关闭DEL弹窗
  closeDEL = () => {
    this.setState({
      isDELOpen: false
    })
  };

  //打开批量DEL弹窗
  changeAllDEL = (e) => {
    e.persist()
    console.log(this.state.selectedRowKeys)
    this.setState({
      isAllDELOpen: true,
    })
  };

  //关闭批量DEL弹窗
  closeAllDEL = () => {
    this.setState({
      isAllDELOpen: false
    })
  };

  //绑定input通用方法
  changeData = (data, e) => {
    console.log(data + '=' + e.target.value)
    this.setState({
      [data]: e.target.value
    })
  }
  
  //绑定請求日
  changeStartDate = (date, dateString) => {
    if(date){
      console.log(dateString)
      //显示用的日期和发送的日期参数格式不一样，必须要分开保存
      this.setState({
        startDate: dateString,
        startDateValue:date
      })
    }else{
      console.log('无')
      this.setState({
        startDate:['', ''],
        startDateValue: ['', '']
      })
    }
  };

  //绑定お支払期限
  changeEndDate = (date, dateString) => {
    if(date){
      console.log(dateString)
      this.setState({
        endDate: dateString,
        endDateValue:date
      })
    }else{
      console.log('无')
      this.setState({
        endDate:['', ''],
        endDateValue: ['', '']
      })
    }
  };

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
      <div className='contractlist'>
        <Modal title="削除" open={this.state.isDELOpen} onOk={this.softDeleteContract} onCancel={this.closeDEL}>
          削除しても大丈夫ですか?
        </Modal>
        <Modal title="一括削除" open={this.state.isAllDELOpen} onOk={this.softAllDeleteContract} onCancel={this.closeAllDEL}>
          一括削除しても大丈夫ですか?
        </Modal>
        <div className='contractlist-title'>
          請求書リスト
        </div>
        <div className='contractlist-content'>
          <div className='contractlist-content-search'>
            <div style={{ float: "right" }}>
              <Button style={{ 'marginRight': '2px', 'marginBottom': '6px' }} onClick={this.resetOrderList}>リセット</Button>
            </div>
            <div>
              <div style={{ float: "left" }}>
                会社名：
                <Input className='contractlist-content-search-input' value={this.state.company} onChange={(e) => this.changeData('company', e)} ref={(val) => this.inputdata = val} allowClear='true' placeholder="XXX株式会社" />
                <Button style={{ 'marginRight': '2px', 'marginBottom': '6px' }} type="primary" onClick={this.getListByCompany}>检索</Button>
              </div>

              <div style={{ float: "left" }}>
                請求日：
                <RangePicker value={this.state.startDateValue} onChange={this.changeStartDate} style={{ 'marginRight': '2px', 'marginBottom': '6px' }} format={dateFormatList} />
                <Button style={{ 'marginRight': '2px', 'marginBottom': '6px' }} type="primary" onClick={this.getListByStartDate}>检索</Button>
              </div>
            </div>

            <div style={{ clear: 'both' }}></div>

            <div>
              <div style={{ float: "left" }}>
                請求番号：
                <Input className='contractlist-content-search-input' value={this.state.number} onChange={(e) => this.changeData('number', e)} ref={(val) => this.inputdata = val} allowClear='true' placeholder="100001" />
                <Button style={{ 'marginRight': '2px', 'marginBottom': '6px' }} type="primary" onClick={this.getListByNumber}>检索</Button>
              </div>

              <div style={{ float: "left" }}>
                お支払期限：
                <RangePicker value={this.state.endDateValue} onChange={this.changeEndDate} style={{ 'marginRight': '2px', 'marginBottom': '6px' }} format={dateFormatList} />
                <Button style={{ 'marginRight': '2px', 'marginBottom': '6px' }} type="primary" onClick={this.getListByEndDate}>检索</Button>
              </div>
            </div>

            <div style={{ clear: 'both' }}></div>

            <div>
              <div style={{ float: "left" }}>
                合計金額：
                <Input className='contractlist-content-search-input' value={this.state.price} onChange={(e) => this.changeData('price', e)} ref={(val) => this.inputdata = val} allowClear='true' placeholder="7000" />
                <Button style={{ 'marginRight': '2px', 'marginBottom': '6px' }} type="primary" onClick={this.getListByPrice}>检索</Button>
              </div>
              <div style={{ float: "left", color: 'red', lineHeight: '30px', marginLeft: '10px' }}>
                &lt;例：02-16の請求書を検索すると02-16 ~ 02-17を入力してください&gt;
              </div>
            </div>

            <div style={{ clear: 'both' }}></div>

          </div>
          <div className='contractlist-content-list'>

            <div style={{ marginBottom: 16 }}>
              <Button type="primary" onClick={this.start} disabled={!hasSelected} loading={this.state.loading}>
                クリア
              </Button>
              <Button style={{ marginLeft: 8 }} type="primary" disabled={!hasSelected} onClick={this.changeAllDEL}>一括削除</Button>
              <span style={{ marginLeft: 8 }}>
                {hasSelected ? ` ${selectedRowKeys.length} 件を選択した` : ''}
              </span>
            </div>

            <Table style={{ overflowX: 'scroll' }} pagination={{ defaultPageSize: 10 }} rowSelection={rowSelection} columns={this.state.columns} dataSource={this.state.data} rowKey="id" />
          </div>
        </div>
      </div>
    );
  }
}

export default ContractList;
