import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect, Switch } from 'react-router-dom';
import '../../assets/Home.css'
import { Layout, Menu } from 'antd';
import ContractList from './ContractList';
import ContractContent from './ContractContent';
import SystemOption from './SystemOption';
import { Dropdown, message } from 'antd';
import store from '../../reducer'
import { changeLoading, changeMenu } from '../../reducer/actionCreators'
import { axiosSign } from '../../api/axiosSign'

import {
  FileSearchOutlined,
  SettingFilled,
  LoginOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';

const { Header, Content, Sider } = Layout;

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      collapsedCss: 'trigger',
      logoCss: 'logo flex-middle',
      layoutCss: 'site-layout',
      userName: 'guest'
    }
    store.subscribe(this.storeChange) //订阅Redux的状态
  }
  storeChange = () => {
    this.setState(store.getState())
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return
    }
  }//在组件销毁的时候将异步方法撤销

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
    if (this.state.collapsedCss === 'trigger') {
      this.setState({
        collapsedCss: 'trigger2',
        logoCss: 'logo2',
        layoutCss: 'site-layout2'
      })
    } else {
      this.setState({
        collapsedCss: 'trigger',
        logoCss: 'logo flex-middle',
        layoutCss: 'site-layout'
      })
    }
  };

  //前往請求書
  goContractList = () => {
    store.dispatch(changeLoading(true))
    setTimeout(() => {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
      this.props.history.push('/home/contractlist');
      store.dispatch(changeLoading(false))
    }, 1000)
  }

  //前往発行
  goSystemOption = () => {
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    this.props.history.push('/home/systemoption');
  }
  //退出登录
  goLogin = () => {
    let action = changeLoading(true)
    store.dispatch(action)

    setTimeout(() => {
      store.dispatch(changeLoading(false))

      axiosSign('logout', '')
        .then((res) => {
          this.props.history.push('/login');
          message.success('Bye')
        })
        .catch((error) => { console.log('axios 获取数据失败' + error) })
    }, 2000)
  }

  //渲染后自动执行
  UNSAFE_componentWillMount() {
    //获取用户名
    axiosSign('getName', '')
    .then((res) => {
      console.log(res.data)
      this.setState({
        userName:res.data
      })
    })
    .catch((error) => { console.log('axios 获取数据失败' + error) })
  }

  render() {
    const admin_items = [
      { label: 'ログアウト', key: 10, icon: <LoginOutlined />, onClick: this.goLogin }
    ]

    const items = [{
      key: '1',
      label: (
        <Menu items={admin_items} />
      )
    }];

    const menu_items = [
      { label: '新規発行', key: '3', icon: <SettingFilled />, onClick: this.goSystemOption },
      { label: '請求書リスト', key: '1', icon: <FileSearchOutlined />, onClick: this.goContractList },
    ]

    return (
      <Layout style={{ height: '100vh' }}>
        <Sider className='home-sider' trigger={null} collapsible collapsed={this.state.collapsed} onCollapse={this.toggle}>
          <div className={this.state.logoCss}>
            <img className='home-logo-img' src='./img/jtmh_logo.png' alt='logo'></img>
            <div className='home-logo-title'>請求書発行システム</div>
          </div>
          <Menu theme="dark" selectedKeys={store.getState().menuValue} mode="inline" items={menu_items} />

          {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: this.state.collapsedCss,
            onClick: this.toggle,
          })}
        </Sider>

        <Layout className={this.state.layoutCss}>
          <Header className="site-layout-background top-shadow" style={{ zIndex: '1', padding: 0 }} >
            <Dropdown className='site-layout-dropdown' menu={{ items }}>
              <p style={{ color: '#1890ff', cursor: 'pointer', margin: '0' }}>
                Hello，<span className='home-userName'>{this.state.userName}</span> !
              </p>
            </Dropdown>
          </Header>
          <Content>
            <Switch>
              <Route path="/home/contractlist" component={ContractList} />
              <Route path="/home/contractcontent" component={ContractContent} />
              <Route path="/home/systemoption" component={SystemOption} />
              <Redirect from="/home" to="/home/systemoption" />
            </Switch>
          </Content>
        </Layout>
      </Layout>

    );
  }
}

export default Home;
